import React, { useState , useEffect } from 'react'
import Dialog from '../Dialog/index';
import { withMobx } from '../MobxHoc';
import './claim.css'
import LoadIcon from '../../../assets/images/Icon-Loading.svg';
import { useAccount, useReadContract, useWriteContract, useBalance, useWatchContractEvent,useWatchAsset } from 'wagmi';
import receiverABI from '../../../abi/celebrity.json';
import config, { viewLink } from '../../../config';
import { formatAdress, convertToDisplayValue, fromatNumToThousands } from '../../../utils';

function Claim(props) {
  const { closeHandle, openResultDialog, openStatementDialog, est, statement, store} = props;
  const [claiming, setClaiming] = useState(false)
  const [claimAmount, setClaimAmount] = useState(0);
  const [contractBalance, setContractBalance] = useState(0);
  const [claimHash, setClaimHash] = useState();
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [death, setDeath] = useState()
  const [showTip, setShowTip] = useState(false)
  const [accountStatement, setAccountStatement] = useState(statement)

  const usdtReceiverAddress = config.usdtReceiverAddress;
  const { writeContractAsync } = useWriteContract();
  const account = useAccount();
  const { data: usdcBalance } = useReadContract({ abi: receiverABI, address: usdtReceiverAddress, functionName: 'balanceOfUSDT', args: [account.address] });


  const claimHandle = async () => {
    if (claiming) { return; }
    if (!account.address || !contractBalance) { return; }
    setClaiming(true);
    try {
      const tx = await writeContractAsync({ abi: receiverABI, address: usdtReceiverAddress, functionName: 'claimUSDT', args: [claimAmount] }, {
        onSuccess: () => {
          // 假如监听不成功，限时调整状态
          setContractBalance(0);
          setClaiming(false);
          setClaimAmount(claimAmount);
          openResultDialog({
            type: 'success',
            amount: fromatNumToThousands(convertToDisplayValue(claimAmount, config.celebrityDecimals, 4)),
            operate: 'cliam',
          });
        },
        onError: () => {
          setClaiming(false);
          openResultDialog({
            type: 'failed',
            // amount: fromatNumToThousands(convertToDisplayValue(claimAmount, 6, 4)),
            operate: 'cliam',
          });
        }
      });
      setClaimHash(tx);
      setTimeout(() => {
        setContractBalance(0);
        setClaiming(false);
        setClaimAmount(claimAmount);
      }, 60000);
    } catch (error) {
      console.log("claim error:", error);
    }
  };

  useEffect(() => {
    if (usdcBalance || usdcBalance === 0n) {
      setClaimAmount(usdcBalance);
      setContractBalance(fromatNumToThousands(convertToDisplayValue(usdcBalance, config.usdtDecimals, 4), true));
    }
  }, [usdcBalance]);

  useEffect(()=>{
    if(statement){
      setAccountStatement(statement)
    }
  },[statement])

  useEffect(()=>{
    setDeath(store.common.celebrityStatus)
  },[store.common.celebrityStatus])

  return (
    <Dialog title={'Claim'} closeHandle={closeHandle} type={'claimDialogComponent'} className={'ClaimDialog'}>
        <div className='txtInfo'>
          <p>
            <span className='label'>Total earnings (USDC)</span>
            <span className='num'>+{accountStatement?.totalEarning && fromatNumToThousands(convertToDisplayValue(accountStatement.totalEarning, config.usdtDecimals, 4), true)}</span>
          </p>
          <p>
            <span className='label'>Claimed (USDC)</span>
            <span className='num'>-{accountStatement?.claimed && fromatNumToThousands(convertToDisplayValue(accountStatement.claimed, config.usdtDecimals, 4), true)}</span>
          </p>
          {!(death?.status === 'living') ? 
            <p className='longevityPool' onClick={()=>setShowTip(!showTip)}>
              <span className='label'>Kim Jong-un's Longevity Pool <i>i</i></span>
              <span className='num'>+{accountStatement?.claimed && fromatNumToThousands(convertToDisplayValue(accountStatement.claimed, config.usdtDecimals, 4), true)}</span>
              <span className={'claimedTip ' +(showTip ? 'show':'hide')}>The amount to be claimed is the estimated amount to be claimed for the current cycle, and will be available only after the cycle is settled.</span>
            </p> : null
            // <p className='tobeclaimed' onClick={()=>setShowTip(!showTip)}>
            //   <span className='label'>To be claimed (USDC) <i>i</i></span>
            //   <span className='num'>+{fromatNumToThousands(contractBalance)}</span>
            //   <span className={'claimedTip ' +(showTip ? 'show':'hide')}>The amount to be claimed is the estimated amount to be claimed for the current cycle, and will be available only after the cycle is settled.</span>
            // </p>
            }
        </div>
        <p className='availableAmount'>
          <span className='label'>Available amount (USDC) </span>
          <span className='num'>{accountStatement?.availableAmount && fromatNumToThousands(convertToDisplayValue(accountStatement.availableAmount, config.usdtDecimals, 4), true)}</span>
        </p>
        <div className={ 'claimBtn '+ (contractBalance > 0n ? '' : ' disabled') } onClick={contractBalance > 0n ? claimHandle : null}> {claiming ? <span className='claiming'><img src={LoadIcon} width={'18px'} height={'18px'} />Claiming…</span> : 'Claim'}</div>
        <p className='link' onClick={openStatementDialog}>
          <span>Account Statement &gt;</span>
        </p>
    </Dialog>
  )
}

export default withMobx(Claim)