import './App.css';
import Routers from './routers';

function App() {

  return (
    <>
      <Routers></Routers>
    </>
  );
}

export default App;
