import React, {useEffect, useState} from 'react';
import FullScreen from '../components/FullScreenDialog'
import './AccountStatement.css'
import { formatAdress, convertToDisplayValue, fromatNumToThousands, formatTime } from '../../utils';
import { withMobx } from '../components/MobxHoc';
import { getAccountStatement } from '../../api';
import config from '../../config'

 function AccountStatement(props) {
  const { closeHandle = ()=>{}, store } = props;

  const [accountStatement, setAccountStatement] = useState(false);
  const [statement, setStatement] = useState()

  const [death, setDeath] = useState(false)
  const [showTip, setShowTip] = useState(false)

  const typeTitles = {
    dividend: "KJUD / KJUD Dividend",
    claimed: "Claimed",
    longevity : "KJUD / Longevity Distribution",
    lucky: "KJUD / No.13 Lucky Rewards"
  }

  const closeStatementDialogHandle = () => {
    setAccountStatement(false)
  }

  const getAccountStatementFn =async () => {
    if(!store.user.authToken){return}
    const res = await getAccountStatement();
    if(res.code == 0){
      setStatement(res.data)
    }
  }


  useEffect(()=>{
    setDeath(store.common.celebrityStatus)
  },[store.common.celebrityStatus])

  useEffect(()=>{
    getAccountStatementFn()
  }, [])

  return (
    <div className='accountStatementComponent'>
      <FullScreen title={'Account Statement'} closeHandle = {closeHandle}>
        <div className='contentWrap'>
          <div className='primary recordsWrap'>
            {
              statement?.list && statement.list.length>0 && statement.list.map((item, i)=>{
                return (
                  <div className={'item '+item.detailType} key={i}>
                    <p className='left'>
                      <span className='type'>{item.detailType === 'lucky' ? "KJUD / No. "+item.usdtPeriodNum+" Lucky Rewards" : typeTitles[item.detailType]}</span>
                      <span className='date'>{item?.operationTime ? formatTime(item?.operationTime * 1000, 'MM/dd/yyyy hh:mm:ss') : '???'}</span>
                    </p>
                    <p className='amount'>{item.detailType === 'claimed' ? '-' : '+'} {item?.amount ? fromatNumToThousands(convertToDisplayValue(item?.amount, config.usdtDecimals, 2),false, 2) : '--'} <span>USDC</span></p>
                  </div>
                )
              })
            }
          </div>
          <div className='siderWrap'>
            <div className='poolInfoWrap'>
              <div className='txtInfo'>
                <p>
                  <span className='label'>Total earnings (USDC)</span>
                  <span className='num'>+{statement?.totalEarning && fromatNumToThousands(convertToDisplayValue(statement?.totalEarning, config.usdtDecimals, 2), false, 2)}</span>
                </p>
                <p>
                  <span className='label'>Claimed (USDC)</span>
                  <span className='num'>-{statement?.claimed && fromatNumToThousands(convertToDisplayValue(statement?.claimed, config.usdtDecimals, 2), false, 2)}</span>
                </p>
                {death?.status == 'living' ? 
                  <p className='longevityPool' onClick={()=>setShowTip(!showTip)}>
                    <span className='label'>Kim Jong-un's Longevity Pool </span>
                    <span className='num'>+{statement?.claimed && fromatNumToThousands(convertToDisplayValue(statement?.claimed, config.usdtDecimals, 2), false, 2)}</span>
                    <span className={'claimedTip ' +(showTip ? 'show':'hide')}>The amount to be claimed is the estimated amount to be claimed for the current cycle, and will be available only after the cycle is settled.</span>
                  </p> : null
                  // <p className='tobeclaimed' onClick={()=>setShowTip(!showTip)}>
                  //   <span className='label'>To be claimed (USDC) <i></i></span>
                  //   <span className='num'>+{fromatNumToThousands(contractBalance)}</span>
                  //   <span className={'claimedTip ' +(showTip ? 'show':'hide')}>The amount to be claimed is the estimated amount to be claimed for the current cycle, and will be available only after the cycle is settled.</span>
                  // </p> 
                  }
              </div>
              <p className='availableAmount'>
                <span className='label'>Available amount (USDC) </span>
                <span className='num'>{statement?.availableAmount && fromatNumToThousands(convertToDisplayValue(statement?.availableAmount, config.usdtDecimals, 2), false, 2)}</span>
              </p>
            </div>
          </div>
        </div>
      </FullScreen>
    </div>
  )
}

export default withMobx(AccountStatement)