import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import rootStore from "./stores";
import '@rainbow-me/rainbowkit/styles.css';
import { Provider } from "mobx-react";
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider, http } from 'wagmi';
// import {
//   // baseSepolia,
//   // mainnet,
//   // polygon,
//   // optimism,
//   // arbitrum,
//   // base,
// } from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import config from './config';

const targetChain = config.targetChain
console.log("targetChain......", targetChain)
const walletConfig = getDefaultConfig({
  appName: 'My RainbowKit App',
  projectId: '9a46e05ba1aab2b43b1d4624b0d90a9f',
  // chains: [mainnet, polygon, optimism, arbitrum, base],
  chains: [targetChain],
  transports: {
    //  [targetChain.id]: http('https://base-sepolia.g.alchemy.com/v2/4SDhdcEj94sPnorsDNuHlt4K7QQ4oB94'),
    // [targetChain.id]: http('https://base-mainnet.g.alchemy.com/v2/4SDhdcEj94sPnorsDNuHlt4K7QQ4oB94'),
    [targetChain.id]: http(config.rpc)
  },
  rpc: '',
  ssr: false, // If your dApp uses server side rendering (SSR)
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiProvider config={walletConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Provider store={rootStore} provider={Provider}>
            <App />
          </Provider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
