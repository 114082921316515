import React, { useEffect, useState } from 'react'
import CloseIcon from '../../../assets/images/Icon-Close_gray.svg'
import './index.css'

export default function Index(props) {
  const {
    closeHandle,
    title,
    children
  } = props

  const closeClick = () => {
    // setShow(false)
    closeHandle && closeHandle()
  }

  useEffect(()=>{
    document.querySelector('body')?.classList.add('noScroll')
    return ()=>{
      document.querySelector('body')?.classList.remove('noScroll')
    }
  },[])
  
  return (
    <div className={'FullScreenDialogComponents'}>
      <div className={'dialogWrap '}>
        <div className={'header ' + (title ? 'hasTitle' : 'noTitle')}>
          {title && <span className='title'>{title}</span>}
          <span className='closeBtn' onClick={closeClick} ><img src={CloseIcon} /></span>
        </div>
        <div className='dialogcontent'>
            {children}
        </div>
      </div>
    </div>
  )
}
