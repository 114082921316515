
import React, { useEffect, useState } from 'react';
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useChains, useChainId, useDisconnect, useSwitchChain, useSignMessage } from 'wagmi';
import { withMobx } from '../MobxHoc';
import { formatAdress, getWalletCurrentChain } from '../../../utils';
import ConnectedDialog from '../connectedDialog/index';
import './index.css';
import Arrow from '../icons/Arrow';
import WarnIcon from '../icons/WarnIcon';
import config from '../../../config';
import { SiweMessage } from "siwe";
import { getAuthTokens } from '../../../api';
import axios from "axios";

/**
 * Custom Wagmi Connect Button
 */
function Index(props) {
  const { store, text } = props;
  const targetNetwork = config.targetChain;
  const walletAccount = useAccount();
  const chainId = useChainId();
  const [showDialog, setShowDialog] = useState(false);

  const origin = window.location.origin;

  const { signMessageAsync, isLoading: signLoading } = useSignMessage();

  function createSiweMessage(address, statement) {
    const domain = window.location.host;
    const origin = window.location.origin;

    const message = new SiweMessage({
      domain,
      address: walletAccount.address,
      statement,
      uri: origin,
      chainId: config.targetChain.id.toString(),
      version: "1",
    });
    return message.prepareMessage();
  }


  const signMessage = async () => {
    console.log("signMessage::: 199");
    const message = createSiweMessage(
      // signer.getAddress(),
      walletAccount.address,
      "Sign in with Ethereum to the app."
    );
    // setSignMessageTxt(message);
    // setSigning(true);

    console.log("sign message signMessageAsync: 203");
    try {
      // console.log("sign message signMessageAsync: 203");
      await signMessageAsync(
        { message: message },
        {
          onSuccess(data) {
            console.log("signMessageAsync::message::", data);
            console.log("signMessageAsync::data::", message, data);
            getAuthTokens({
              signature: data,
              message: message,
              // signature: data,
              // message: messageTxt
            }).then((data) => {
              console.log('useSignMessage onSuccess::11: getAuthTokens', data.data.token);
              window.localStorage.setItem('token', data.data.token);
              store.user.setAuthToken(data.data.token);
            });
            console.log('useSignMessage onSuccess::11:', data);
          },
          onError(error) {
            console.log("useSignMessage onFailed:", error);
          },
          onSettled(data, error) {
            console.log("useSignMessage wagmi sign message Settled", { data, error });
          },
          onMutate(args) {
            console.log("useSignMessage wagmi sign message Mutate", args);
          },
        }
      );
      // setSigning(false);
    } catch (error) {
      console.log("You need to confirm your signature to sign in", error);
    }
  };


  useEffect(() => {
    if (walletAccount.address && !store.user.authToken) {
      signMessage();
    }
  }, [walletAccount.address, store.user.authToken]);


  return (
    <div className='connectButton'>
      <ConnectButton.Custom>
        {({ account, chain, openConnectModal, mounted }) => {
          const connected = mounted && account && chain;

          return (
            <>
              {(() => {
                if (!connected) {
                  return (
                    <p className="connectBtn" onClick={openConnectModal} >
                      <svg xmlns="http://www.w3.org/2000/svg" id="head-wallet" fill='currentColor' viewBox="0 0 14.78 14"><defs><style>.cls-1{ }</style></defs><g id="head-wallet1" data-name="head-wallet"><g id="head-wallet-2" data-name="head-wallet 1"><path class="cls-1" d="M14.32,3.57A1.66,1.66,0,0,0,14,3.35V1.56a1.47,1.47,0,0,0-.46-1.1A1.47,1.47,0,0,0,12.44,0H1.56A1.47,1.47,0,0,0,.46.46,1.47,1.47,0,0,0,0,1.56V12.44a1.47,1.47,0,0,0,.46,1.1,1.47,1.47,0,0,0,1.1.46H12.44A1.57,1.57,0,0,0,14,12.44V10.65a1.66,1.66,0,0,0,.32-.22,1.47,1.47,0,0,0,.46-1.1V4.67A1.47,1.47,0,0,0,14.32,3.57Zm-1.88,8.87H1.56V1.56H12.44V3.11H7.78a1.47,1.47,0,0,0-1.1.46,1.47,1.47,0,0,0-.46,1.1V9.33a1.57,1.57,0,0,0,1.56,1.56h4.66Zm.78-3.11H7.78V4.67h5.44ZM9.29,7.83a1.17,1.17,0,0,1,.82-2,1.16,1.16,0,0,1,.83.34,1.18,1.18,0,0,1,0,1.66,1.16,1.16,0,0,1-.83.34A1.13,1.13,0,0,1,9.29,7.83Z" /></g></g></svg>
                      <span className='txt'>{text ? text : 'Connect Wallet'}</span>
                    </p>
                  );
                }

                if (chain.unsupported || chain.id !== targetNetwork.id) {
                  const curChain = getWalletCurrentChain(walletAccount.chainId);
                  return (<div className={'wrongChain ' + (showDialog ? 'open' : '')} onClick={() => { setShowDialog(!showDialog); }}>
                    <span className='icon' >
                      <WarnIcon w='15px' h='14px'></WarnIcon>
                      <i className='h5'>
                        <svg xmlns="http://www.w3.org/2000/svg" id="head-wallet" fill='currentColor' viewBox="0 0 14.78 14"><defs><style>.cls-1{ }</style></defs><g id="head-wallet1" data-name="head-wallet"><g id="head-wallet-2" data-name="head-wallet 1"><path class="cls-1" d="M14.32,3.57A1.66,1.66,0,0,0,14,3.35V1.56a1.47,1.47,0,0,0-.46-1.1A1.47,1.47,0,0,0,12.44,0H1.56A1.47,1.47,0,0,0,.46.46,1.47,1.47,0,0,0,0,1.56V12.44a1.47,1.47,0,0,0,.46,1.1,1.47,1.47,0,0,0,1.1.46H12.44A1.57,1.57,0,0,0,14,12.44V10.65a1.66,1.66,0,0,0,.32-.22,1.47,1.47,0,0,0,.46-1.1V4.67A1.47,1.47,0,0,0,14.32,3.57Zm-1.88,8.87H1.56V1.56H12.44V3.11H7.78a1.47,1.47,0,0,0-1.1.46,1.47,1.47,0,0,0-.46,1.1V9.33a1.57,1.57,0,0,0,1.56,1.56h4.66Zm.78-3.11H7.78V4.67h5.44ZM9.29,7.83a1.17,1.17,0,0,1,.82-2,1.16,1.16,0,0,1,.83.34,1.18,1.18,0,0,1,0,1.66,1.16,1.16,0,0,1-.83.34A1.13,1.13,0,0,1,9.29,7.83Z" /></g></g></svg>
                      </i>
                    </span>
                    <span className='txt'>{account.displayName} <em>{curChain?.name}</em></span>
                    {/* <span className='arrow'><Arrow ></Arrow></span> */}
                  </div>);
                }

                return (
                  <>
                    {/* <span onClick={() => signMessage({ message: 'hello world' })}>signature</span> */}
                    <div className={"correctConnected " + (showDialog ? 'open' : '')} onClick={() => { setShowDialog(!showDialog); }}>
                      <span className='icon' >
                        <span className='txt connected h5'>Connected</span>
                        <svg xmlns="http://www.w3.org/2000/svg" id="head-wallet" fill='currentColor' viewBox="0 0 14.78 14"><defs><style>.cls-1{ }</style></defs><g id="head-wallet1" data-name="head-wallet"><g id="head-wallet-2" data-name="head-wallet 1"><path class="cls-1" d="M14.32,3.57A1.66,1.66,0,0,0,14,3.35V1.56a1.47,1.47,0,0,0-.46-1.1A1.47,1.47,0,0,0,12.44,0H1.56A1.47,1.47,0,0,0,.46.46,1.47,1.47,0,0,0,0,1.56V12.44a1.47,1.47,0,0,0,.46,1.1,1.47,1.47,0,0,0,1.1.46H12.44A1.57,1.57,0,0,0,14,12.44V10.65a1.66,1.66,0,0,0,.32-.22,1.47,1.47,0,0,0,.46-1.1V4.67A1.47,1.47,0,0,0,14.32,3.57Zm-1.88,8.87H1.56V1.56H12.44V3.11H7.78a1.47,1.47,0,0,0-1.1.46,1.47,1.47,0,0,0-.46,1.1V9.33a1.57,1.57,0,0,0,1.56,1.56h4.66Zm.78-3.11H7.78V4.67h5.44ZM9.29,7.83a1.17,1.17,0,0,1,.82-2,1.16,1.16,0,0,1,.83.34,1.18,1.18,0,0,1,0,1.66,1.16,1.16,0,0,1-.83.34A1.13,1.13,0,0,1,9.29,7.83Z" /></g></g></svg>
                      </span>
                      <span className="txt">
                        {account.displayName}
                        <em>{chain.name}</em>
                      </span>
                      {/* <span className='arrow'><Arrow ></Arrow></span> */}
                    </div>
                  </>
                );
              })()}
            </>
          );
        }}
      </ConnectButton.Custom>
      {showDialog && <ConnectedDialog closeHandle={setShowDialog}></ConnectedDialog>}
    </div>
  );
}

export default withMobx(Index);