import { makeAutoObservable} from "mobx";
import UserStore from "./user.store";
import CommonStore from "./common.store"

export class RootStore {
  user = new UserStore();
  common = new CommonStore();
  
  constructor() {
    makeAutoObservable(this);
  }
}

const rootStore = new RootStore();
export default rootStore;
