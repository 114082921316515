import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ConnectButton from "./RainbowKitCustomConnectButton/index";
import { withMobx } from './MobxHoc';
import './header.css'
import Logo from '../../assets/images/logo.svg'
import MobileTip from '../components/mobileTip/mobileTip'
import { tokenPool, howItWork, faq, telegram, x } from '../../config';
import { useNavigate } from 'react-router-dom';
import xLogo from '../../assets/images/Icon-X.svg'
import telLogo from '../../assets/images/Icon-Telegram.svg'
import linktreeLogo from '../../assets/images/Icon-Linktree.svg'
import { getCelebrityStatus } from '../../api';
import config from '../../config';

function Header(props) {
  const { store } = props;
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [openTip, setOpenTip] = useState(false)
  const navigate = useNavigate();
  const _location = useLocation();


  const getCelebrityStatusFn = async () =>{
      const res = await getCelebrityStatus({contractAddress: config.usdtReceiverAddress});
      // console.log("getCelebrityStatusFn::::", res.data.status)
      if(res.code == 0){
        store.common.setCelebrityStatus(res.data)
      }
  }

  const tokenPoolJumpHandle = () => {
    if(_location.pathname != '/'){
      navigate('/')
    }
    const _homepageScrollWrap = document.documentElement
    _homepageScrollWrap.scrollTo(0, 500)
  }

  const jump = (type)=>{
    const links = {howItWork,telegram,x}
    setShowMobileNav(false)
    if(type=== 'home'){
      navigate('/')
    }else{
      navigate('/'+type)
      // window.open(links[type],  '_blank')
    }
  }

  const closeHandle = () => {
    setOpenTip(false)
  }
  

  useEffect(()=>{
    getCelebrityStatusFn()
  }, [])

  return (
    <div className='headerWrap'>
      <div className='inner pc'>
        <div className='leftWrap'>
          <div className='logo'><a href={'/'}><img src={Logo} width={'132px'} height={'62px'}  /></a></div>
          <div className='nav'>
            {/* href={tokenPool} */}
            <span onClick={tokenPoolJumpHandle}>/ Token Pools /</span>
            <a href={howItWork}>/ How it works /</a>
            <a href={faq}>/ FAQ /</a>
            {/* <a href={telegram} target='_blank'>/ Telegram /</a> */}
            {/* <a href={x} target='_blank'>/ X (Twitter) /</a> */}
          </div>
        </div>
        <ConnectButton></ConnectButton>
      </div>
      <div className='inner h5'>
        <div className='headerShow'>
          <span className='menu' onClick={()=>{setShowMobileNav(!showMobileNav)}}></span>
          <div className='logo'><a href={'/'}><img src={Logo} width={'51px'} height={'24px'}  /></a></div>
          {/* <span className='walletIcon' onClick={()=>{setOpenTip(true)}}>
            <svg xmlns="http://www.w3.org/2000/svg"  id="head-wallet" fill='currentColor' viewBox="0 0 14.78 14"><defs><style>.cls-1{}</style></defs><g id="head-wallet1" data-name="head-wallet"><g id="head-wallet-2" data-name="head-wallet 1"><path class="cls-1" d="M14.32,3.57A1.66,1.66,0,0,0,14,3.35V1.56a1.47,1.47,0,0,0-.46-1.1A1.47,1.47,0,0,0,12.44,0H1.56A1.47,1.47,0,0,0,.46.46,1.47,1.47,0,0,0,0,1.56V12.44a1.47,1.47,0,0,0,.46,1.1,1.47,1.47,0,0,0,1.1.46H12.44A1.57,1.57,0,0,0,14,12.44V10.65a1.66,1.66,0,0,0,.32-.22,1.47,1.47,0,0,0,.46-1.1V4.67A1.47,1.47,0,0,0,14.32,3.57Zm-1.88,8.87H1.56V1.56H12.44V3.11H7.78a1.47,1.47,0,0,0-1.1.46,1.47,1.47,0,0,0-.46,1.1V9.33a1.57,1.57,0,0,0,1.56,1.56h4.66Zm.78-3.11H7.78V4.67h5.44ZM9.29,7.83a1.17,1.17,0,0,1,.82-2,1.16,1.16,0,0,1,.83.34,1.18,1.18,0,0,1,0,1.66,1.16,1.16,0,0,1-.83.34A1.13,1.13,0,0,1,9.29,7.83Z"/></g></g></svg>
          </span> */}
          <ConnectButton></ConnectButton>
        </div>
        {showMobileNav ?
        <div className='navWrap'>
          <div className='nav'>
            {/* <span onClick={()=>jump('howItWork')}>/ How it works /</span>
            <span onClick={()=>jump('telegram')} >/ Telegram /</span>
            <span onClick={()=>jump('x')} >/ X (Twitter) /</span>
            <span onClick={()=>jump('home')} >/ Home /</span> */}
            <span onClick={()=>jump('pool')}>/ Token Pools /</span>
            <span onClick={()=>jump('howitwork')} >/ How it works /</span>
            <span onClick={()=>jump('faq')} >/ FAQ /</span>
            <span onClick={()=>jump('home')} >/ Home /</span>
            {/* <a href={tokenPool}>/ Token Pools /</a>
            <a href={howItWork}>/ How it works /</a>
            <a href={faq}>/ FAQ /</a>
            <a href='/'>/ Home /</a> */}
          </div>
          {/* <div className='mobileTipWrap'>
            <MobileTip />
          </div> */}
          <div className='mediaWrap'>
            <div className='mediaItem x'><a href={x} target='_blank'><img src={xLogo} /></a></div>
            <div className='mediaItem tel'><a href={telegram} target='_blank'><img src={telLogo} /></a></div>
            <div className='mediaItem xx'><a href={telegram} target='_blank'><img src={linktreeLogo} /></a></div>
          </div>
        </div> : null}
      </div>

      {/* {openTip && <Dialog title={'Connect Wallet'} closeHandle = {closeHandle}>
        <div className={'mobileTipWrap warnDialogContent'}>
          <MobileTip />
        </div>
      </Dialog>} */}
    </div>
  )
}

export default withMobx(Header)
