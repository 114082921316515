import React, {Suspense, lazy, useState, useEffect} from 'react'
import { HashRouter, Routes, Route ,Navigate} from 'react-router-dom';
import Header from './pages/components/header';
const Home = lazy(() => import('./pages/home'))
const Work = lazy(()=> import('./pages/howitwork'))
const Pool = lazy(()=> import('./pages/pool/index'))

const Index = props => {
    const {store} = props
    
    return (
        <Suspense fallback={<span></span>}>
            <HashRouter>
                <Header></Header>
                <Routes>
                    <Route exact path="/" element={<Home></Home>}/>
                    <Route exact path="/howitwork" element={<Work></Work>}/>
                    <Route exact path="/faq" element={<Work></Work>}/>
                    <Route exact path='/KJUD-Token-Pool' element={<Pool></Pool>}></Route>
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </HashRouter>
        </Suspense>
    )
}

export default Index
