import axios from "axios";
// import { getUserinfo } from "@/utils/user";


export const axiosInstance = axios.create({
  // baseURL: baseURL,
  timeout: 120000,
  // withCredentials: true,
  headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
  
});

axiosInstance.interceptors.request.use(
  function (config) {
    // let _userinfo = getUserinfo();
    // config.headers.authorization = _userinfo?.token;
    config.headers.Authorization = window.localStorage.getItem('token') || ''
    if(config.accept){
      config.headers.Accept = config.accept;
    }
    console.log("axiosInstance.interceptors.request:", config)
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    
    if (res.data.success == false && res.data.code == "NEED_LOGIN") {
      // window.location.href = "/";
      return res;
    }
    return res;
  },
  (error) => {
    if (error.code == "ERRORCODE") {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default function (config) {
  // console.log("axiosInstance.interceptors:::", config)
  return axiosInstance(config).catch(function (res) {
    return res;
  });
}
