import React, {useEffect, useState} from 'react';
import ResultDialog from '../components/resultDialog';
import Claim from '../components/Claim/claimDialog';
import FullScreen from '../components/FullScreenDialog'
import './claimDialog.css'
import { formatAdress, convertToDisplayValue, fromatNumToThousands, formatTime } from '../../utils';
import { withMobx } from '../components/MobxHoc';
import { getAccountStatement } from '../../api';
import config from '../../config'

 function ClaimDialog(props) {
  const { openClaimDialog , closeHandle = ()=>{}, store } = props;

  const [showClaimDialog, setShowClaimDialog] = useState(openClaimDialog);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [type, setType] = useState('success');
  const [operate, setOperate] = useState('send');
  const [title, setTitle] = useState('Claimed');
  const [amount, setAmount] = useState(0);
  const [accountStatement, setAccountStatement] = useState(false);
  const [contractBalance, setContractBalance] = useState(0);
  const [statement, setStatement] = useState()

  const [death, setDeath] = useState(false)
  const [showTip, setShowTip] = useState(false)

  const typeTitles = {
    dividend: "KJUD / KJUD Dividend",
    claimed: "Claimed",
    longevity : "KJUD / Longevity Distribution",
    lucky: "KJUD / No.13 Lucky Rewards"
  }

  const openResultDialog = (param) => {
    setAmount(param?.amount || 0);
    setType(param?.type || 'success');
    setOperate(param?.operate || 'send');
    setTitle(param?.type === 'success' ?  "Claimed" : '');
    setShowResultDialog(true);
  };

  const closeStatementDialogHandle = () => {
    setAccountStatement(false)
  }

  const openStatementDialogHandle = () => {
    setAccountStatement(true)
  }

  const getAccountStatementFn =async () => {
    if(!store.user.authToken){return}
    const res = await getAccountStatement();
    if(res.code == 0){
      setStatement(res.data)
    }
  }

  useEffect(()=>{
    setShowClaimDialog(openClaimDialog)
  }, [openClaimDialog])


  useEffect(()=>{
    setDeath(store.common.celebrityStatus)
  },[store.common.celebrityStatus])

  useEffect(()=>{
    getAccountStatementFn()
  }, [])

  return (
    <div className='claimDialogComponent'>
      {showClaimDialog && <Claim openResultDialog={openResultDialog} est={true} closeHandle={() => closeHandle()} openStatementDialog = {()=>{openStatementDialogHandle()}} statement={statement}></Claim>}
      {showResultDialog && <ResultDialog type={type} amount={amount} operate={operate} title={title} closeHandle={() => setShowResultDialog(false)}></ResultDialog>}
      { 
      accountStatement ?
        <FullScreen title={'Account Statement'} closeHandle = {closeStatementDialogHandle}>
          <div className='contentWrap'>
            <div className='primary recordsWrap'>
              {
                statement?.list && statement.list.length>0 && statement.list.map((item, i)=>{
                  return (
                    <div className={'item '+item.detailType} key={i}>
                      <p className='left'>
                        <span className='type'>{item.detailType === 'lucky' ? "KJUD / No. "+item.usdtPeriodNum+" Lucky Rewards" : typeTitles[item.detailType]}</span>
                        <span className='date'>{item?.operationTime ? formatTime(item?.operationTime * 1000, 'MM/dd/yyyy hh:mm:ss') : '???'}</span>
                      </p>
                      <p className='amount'>{item.detailType === 'claimed' ? '-' : '+'} {item?.amount ? fromatNumToThousands(convertToDisplayValue(item?.amount, config.usdtDecimals, 2),false, 2) : '--'} <span>USDC</span></p>
                    </div>
                  )
                })
              }
            </div>
            <div className='siderWrap'>
              <div className='poolInfoWrap'>
                <div className='txtInfo'>
                  <p>
                    <span className='label'>Total earnings (USDC)</span>
                    <span className='num'>+{statement?.totalEarning && fromatNumToThousands(convertToDisplayValue(statement?.totalEarning, config.usdtDecimals, 2), false, 2)}</span>
                  </p>
                  <p>
                    <span className='label'>Claimed (USDC)</span>
                    <span className='num'>-{statement?.claimed && fromatNumToThousands(convertToDisplayValue(statement?.claimed, config.usdtDecimals, 2), false, 2)}</span>
                  </p>
                  {death?.status == 'living' ? 
                    <p className='longevityPool' onClick={()=>setShowTip(!showTip)}>
                      <span className='label'>Kim Jong-un's Longevity Pool </span>
                      <span className='num'>+{statement?.claimed && fromatNumToThousands(convertToDisplayValue(statement?.claimed, config.usdtDecimals, 2), false, 2)}</span>
                      <span className={'claimedTip ' +(showTip ? 'show':'hide')}>The amount to be claimed is the estimated amount to be claimed for the current cycle, and will be available only after the cycle is settled.</span>
                    </p> : null
                    // <p className='tobeclaimed' onClick={()=>setShowTip(!showTip)}>
                    //   <span className='label'>To be claimed (USDC) <i></i></span>
                    //   <span className='num'>+{fromatNumToThousands(contractBalance)}</span>
                    //   <span className={'claimedTip ' +(showTip ? 'show':'hide')}>The amount to be claimed is the estimated amount to be claimed for the current cycle, and will be available only after the cycle is settled.</span>
                    // </p> 
                    }
                </div>
                <p className='availableAmount'>
                  <span className='label'>Available amount (USDC) </span>
                  <span className='num'>{statement?.availableAmount && fromatNumToThousands(convertToDisplayValue(statement?.availableAmount, config.usdtDecimals, 2), false, 2)}</span>
                </p>
              </div>
            </div>
          </div>
        </FullScreen> : null
      }
    </div>
  )
}

export default withMobx(ClaimDialog)