import React, { useState } from 'react'
import CloseIcon from '../../../assets/images/Icon-Close.svg'
import './index.css'

export default function Index(props) {
  const {
    closeHandle,
    title,
    children,
    type,
    className
  } = props

  const closeClick = (e) => {
    // setShow(false)
    if(e.target.className.indexOf('DialogComponents') > -1 || e.target.className.indexOf('closeBtn') > -1)
    closeHandle && closeHandle()
  }
  
  return (
    <div className={'DialogComponents '+className+"Wrap"} onClick={closeClick}>
      <div className={'dialogWrap ' + type+' '+className}>
        <div className={'header ' + (title ? 'hasTitle' : 'noTitle')}>
          {title && <span className='title'>{title}</span>}
          <span className='closeBtn' onClick={closeClick} ><img className='closeBtnImg' src={CloseIcon} width={'16px'} height={'16px'} /></span>
        </div>
        <div className='dialogcontent'>
            {children}
        </div>
      </div>
    </div>
  )
}
