import request from "../api/request";
import config from "../config";

/**
 * @method Auth
 * @param {Object} params:  message, signature
 * @param return promise
 */
export const getAuthTokens = async (params) => {
  try {
    return request({
      url: config.baseURL + `/api/auth/siwe`,
      data: params,
      method: "post",
    }).then((res) => {
      return res?.data || res;
    });
  } catch (error) {
    return error;
  }
};

/**
 * @method getMiningPoolInfo
 * @param return promise
 */

export const getMiningPoolInfo = async () => {
  try {
    return request({
      url: config.baseURL + `/api/mining_pool`,
      method: "get",
    }).then((res) => {
      return res?.data || res;
    });
  } catch (error) {
    return error;
  }
};

/**
 * @method getEarningPoolInfo
 * @param return promise
 */

export const getEarningPoolInfo = async () => {
  try {
    return request({
      url: config.baseURL + `/api/earning_pool/dividend`,
      method: "get",
    }).then((res) => {
      return res?.data || res;
    });
  } catch (error) {
    return error;
  }
};

/**
 * @method getLuckyPoolInfo
 * @param return promise
 */

export const getLuckyPoolInfo = async () => {
  try {
    return request({
      url: config.baseURL + `/api/lucky_pool`,
      method: "get",
    }).then((res) => {
      return res?.data || res;
    });
  } catch (error) {
    return error;
  }
};


/**
 * @method getLongevityInfo
 * @param return promise
 */

export const getLongevityInfo = async () => {
  try {
    return request({
      url: config.baseURL + `/api/earning_pool/longevity`,
      method: "get",
    }).then((res) => {
      return res?.data || res;
    });
  } catch (error) {
    return error;
  }
};

/**
 * @method getYourbets
 * @param return promise
 */

export const getYourbets = async (param) => {
  try {
    return request({
      url: config.baseURL + `/api/lucky_pool/yourbets`,
      method: "get",
      params: param,
    }).then((res) => {
      return res?.data || res;
    });
  } catch (error) {
    return error;
  }
};

/**
 * @method getCelebrityStatus
 * @param return promise
 * @response  state// living、tobeclosed、closed 
 */

export const getCelebrityStatus = async (param) => {
  try {
    return request({
      url: config.baseURL + `/api/celebrity/status`,
      params: param,
      method: "get",
    }).then((res) => {
      return res?.data || res;
    });
  } catch (error) {
    return error;
  }
};

/**
 * @method getAccountStatement
 * @param return promise
 * 获取claim记录
 */

export const getAccountStatement = async (param) => {
  try {
    return request({
      url: config.baseURL + `/api/account/statement`,
      params: param,
    }).then((res) => {
      return res?.data || res;
    });
  } catch (error) {
    return error;
  }
};

/**
 * @method getLuckyPoolRewards
 * @param return promise
 * 获取lucky_pool 最后一个周期 中奖池里待分配的金额记录
 */

export const getLuckyPoolRewards = async () => {
  try {
    return request({
      url: config.baseURL + `/api/lucky_pool/rewards`
    }).then((res) => {
      return res?.data || res;
    });
  } catch (error) {
    return error;
  }
};
