import { makeAutoObservable } from "mobx";

export default class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  celebrityStatus = null;

  setCelebrityStatus = ( status ) => {
    this.celebrityStatus = status;
  };
}
