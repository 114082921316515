import React from 'react'
import Dialog from './Dialog/index'
import UIcon from '../../assets/images/Icon-USDC.svg'
import FailedIcon from '../../assets/images/Icon-Warn.svg'
import './resultDialog.css'
import {fromatNumToThousands} from '../../utils'

// type : [success | failed]
// operate: [send, claimed]
export default function resultTip(props) {
  const {type, amount, operate, title, closeHandle} = props
  return (
    <Dialog title={title} closeHandle = {closeHandle} type={type}>
      <div className={'resultTipContent ' + type }>
          {
            type == 'success' ? 
            <div className='successTip'>
              <div className='top'>
               <img src={UIcon} width={'24px'} height={'24px'}></img>
               <p>{fromatNumToThousands(amount)}<span>USDC</span></p>
              </div>
              <p className='tips'>{operate == 'send' ? 'Successfully Transferred' : 'Successfully Claimed'}</p>

            </div> : <div className='failedTip'>
              <p className='tips'><img src={FailedIcon} width={'30px'} height={'30px'}></img>{operate == 'send' ? 'Sent Failed!' : 'Claim Failed!'}</p>
            </div>
          }
          <div className='closeBtn' onClick={closeHandle}>Close</div>
      </div>
    </Dialog>
  )
}
