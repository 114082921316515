import React from 'react'

export default function WrongIcon(props) {
    const {w, h} = props
  return (
    <svg width={w} height={h} viewBox="0 0 30 28" id='warn-icon'  fill="currentColor" version="1.1">
    <title>􀇿@3x</title>
    <g id="V1.5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Send-&amp;-Claim-Layer-交互状态" transform="translate(-172, -2917)" fill='currentColor' fill-rule="nonzero">
            <g id="Layer备份-8" transform="translate(9, 2820)">
                <g id="编组-2" transform="translate(82, 56)">
                    <g id="􀇿" transform="translate(81.7566, 41.7383)">
                        <path d="M3.58886719,26.6308594 L25.3125,26.6308594 C26.0351562,26.6308594 26.6674805,26.4697266 27.2094727,26.1474609 C27.7514648,25.8251953 28.1689453,25.390625 28.4619141,24.84375 C28.7548828,24.296875 28.9013672,23.6962891 28.9013672,23.0419922 C28.9013672,22.7490234 28.8623047,22.4536133 28.7841797,22.1557617 C28.7060547,21.8579102 28.5839844,21.5673828 28.4179688,21.2841797 L17.5341797,1.83105469 C17.2021484,1.22558594 16.7578125,0.769042969 16.2011719,0.461425781 C15.6445312,0.153808594 15.0585938,0 14.4433594,0 C13.8476562,0 13.269043,0.153808594 12.7075195,0.461425781 C12.1459961,0.769042969 11.6943359,1.22558594 11.3525391,1.83105469 L0.483398438,21.2988281 C0.161132812,21.875 0,22.4560547 0,23.0419922 C0,23.6962891 0.146484375,24.296875 0.439453125,24.84375 C0.732421875,25.390625 1.14990234,25.8251953 1.69189453,26.1474609 C2.23388672,26.4697266 2.86621094,26.6308594 3.58886719,26.6308594 Z M14.4726562,17.0361328 C13.5839844,17.0361328 13.125,16.5917969 13.0957031,15.703125 L12.890625,8.96484375 C12.8710938,8.515625 13.0078125,8.14941406 13.3007812,7.86621094 C13.59375,7.58300781 13.9794922,7.44140625 14.4580078,7.44140625 C14.9267578,7.44140625 15.3076172,7.58544922 15.6005859,7.87353516 C15.8935547,8.16162109 16.0351562,8.52539062 16.0253906,8.96484375 L15.8056641,15.6884766 C15.7763672,16.5869141 15.3320312,17.0361328 14.4726562,17.0361328 Z M14.4726562,21.8701172 C13.984375,21.8701172 13.5668945,21.7163086 13.2202148,21.4086914 C12.8735352,21.1010742 12.7001953,20.7128906 12.7001953,20.2441406 C12.7001953,19.7753906 12.8735352,19.387207 13.2202148,19.0795898 C13.5668945,18.7719727 13.984375,18.6181641 14.4726562,18.6181641 C14.9609375,18.6181641 15.3759766,18.7719727 15.7177734,19.0795898 C16.0595703,19.387207 16.2304688,19.7753906 16.2304688,20.2441406 C16.2304688,20.7226562 16.0571289,21.1132812 15.7104492,21.4160156 C15.3637695,21.71875 14.9511719,21.8701172 14.4726562,21.8701172 Z" id="形状"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
  )
}
