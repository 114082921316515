import * as chains from "viem/chains";
// import BigInt from 'BigInt'

export const formatAdress = (address, startNum) => {
  return address ? address.substr(0, startNum ? startNum : 6) + "..." + address.substr(-4, 4) : "";
};

export const getWalletCurrentChain = (id)=>{
  return Object.values(chains).find(chain => chain.id === id );
}

// 展示转换函数
export const convertToDisplayValue = (value, decimals, precision = decimals) => {
  if(!value){return 0}
  let bigValue = window.BigInt(value);
  let divisor = window.BigInt(10) ** window.BigInt(decimals);
  
  let displayValue = Number(bigValue) / Number(divisor);
  
  return displayValue.toFixed(precision);
}

export const fromatNumToThousands = (num, space = false, n = 2) => {
  if (typeof Number(num) !== "number") {
    return "-";
  }
  if (!num || num == 0) {
    return 0;
  }
  const _numArr = num.toString().split(".");
  _numArr[0] = numThousandsFormat(_numArr[0]);

  if(n == 0){
    return _numArr[0];
  }

  if (_numArr[1] && (_numArr[1] + "").length > n) {
    _numArr[1] = (_numArr[1] + "").slice(0, n);
  }

  return space ? _numArr.join(" . ") : _numArr.join(".");
  
};

function numThousandsFormat(num) {
  const res = num.toString().replace(/\d+/, function (n) {
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ",";
    });
  });
  return res;
}

function padLeftZero(str) {
  return ('00' + str).substring(str.length);
}

export function formatTime(time, fmt = "yyyy-MM-dd") {
  if (typeof time === 'string') {
    time = parseInt(time);
  }
  const date = new Date(time);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}

export const formatTranNumber = (num_, point = 2) => {
  const num = Number(num_)
  if (num < 1e4) {
    return num.toFixed(point, 1)
  }
  if (num >= 1e4 && num < 1e6) {
    return (num / 1e3).toFixed(point, 1) + '<i>K</i>'
  } else if (num >= 1e6 && num < 1e9) {
    return (num / 1e6).toFixed(point, 1) + '<i>M</i>'
  } else if (num >= 1e9 && num < 1e12) {
    return (num / 1e9).toFixed(point, 1) + '<i>B</i>'
  } else if (num >= 1e12) {
    return (num / 1e12).toFixed(point, 1) + '<i>T</i>'
  } else {
    return num.toFixed(point, 1)
  }
}

export function filterOnGoingPool (list) {
  return list.filter(item => item.status === 'ongoing')
}

export function filterpreCyclePools (list) {
  return list.filter(item => item.status === 'closed')
}