import { makeAutoObservable } from "mobx";
export default class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  authToken = localStorage.getItem("token");

  setAuthToken = (token) => {
    this.authToken = token;
  };
}
